<template>
  <v-card
    class="intro__wrapper mx-auto pt-10 pb-5 px-2 px-md-10 text-center"
    elevation="5"
    width="100%"
    max-width="40rem"
    rounded="lg"
    :style="`background-color: ${layerColor}`"
  >
    <v-card-title>
      <Logo class="mx-auto" :logo="contractLogo" :white="false" larger />
    </v-card-title>

    <v-card-subtitle v-if="contractAvatar !== null" class="mt-md-5">
      <v-avatar size="140">
        <img :src="contractAvatar" alt="Proposer logo" />
      </v-avatar>
    </v-card-subtitle>

    <v-card-text v-if="contract.author_name && contractTitle">
      <v-row>
        <v-col cols="12">
          <h2
            class="headline font-weight-medium text-center"
            v-html="
              `${contract.author_name}<br />${$tc(
                'signing.counterparty_intro_message',
                hasFlattenFirstScreen ? contractDocuments.length : 1,
              )}`
            "
            :style="`color: ${primaryTextColor}`"
          ></h2>
        </v-col>

        <v-col cols="12" class="py-0">
          <div v-if="hasFlattenFirstScreen" class="contract__data-text text-center">
            <p
              v-for="document in contractDocuments"
              :key="document.id"
              class="font-weight-regular"
              :style="`color: ${primaryColor}`"
            >
              {{ document.contract_title }}
            </p>
          </div>

          <div v-else class="contract__data-download py-5 px-4">
            <v-btn
              text
              block
              :color="primaryColor"
              class="contract__data-download-button"
              :disabled="!acceptsConditions && !hideTermsAgreement"
              @click="onView"
            >
              <v-icon left>mdi-file-eye-outline</v-icon>
              <span
                class="text-decoration-underline font-weight-medium"
                style="
                  max-width: 155px;
                  overflow: hidden !important;
                  white-space: nowrap !important;
                  text-overflow: ellipsis !important;
                "
              >
                {{ contractTitle }}
              </span>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12">
          <h2 class="headline font-weight-medium text-center" :style="`color: ${primaryTextColor}`">
            {{ $t('signing.counterparty_intro_message_continuation') }}
          </h2>
        </v-col>

        <v-col cols="auto" class="mx-auto text-center">
          <p v-if="hideTermsAgreement" class="mb-5">
            <small
              v-html="agreeWithTosText"
              class="text-with-link font-weight-regular"
              :style="`color: ${primaryTextColor} !important; a > color: ${primaryTextColor} !important`"
            ></small>
          </p>

          <v-checkbox
            v-else
            v-model="acceptsConditions"
            :label="$t('login.agree_with_tos')"
            class="mt-0"
            :color="primaryColor"
          >
            <template v-slot:label>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator>
                    <p
                      v-html="$t('login.agree_with_tos')"
                      class="text-with-link font-weight-regular mb-0"
                      :style="`color: ${primaryTextColor}`"
                      @click.stop
                    ></p>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12">
          <v-btn
            dark
            x-large
            min-width="15rem"
            :color="primaryColor"
            :disabled="!acceptsConditions && !hideTermsAgreement"
            @click="onView"
          >
            {{ $t('contract.show_contract') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-row align="center" justify="center" class="mt-15" no-gutters>
      <v-col cols="auto" class="pb-0">
        <v-btn
          class="text-decoration-underline subtitle-1 font-weight-medium"
          :disabled="(!acceptsConditions && !hideTermsAgreement) || !canRejectContract"
          text
          @click="rejectRequest = true"
        >
          {{ $t('general.decline') }}
        </v-btn>
      </v-col>
    </v-row>

    <RejectContractDialog v-model="rejectRequest" />
  </v-card>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import RejectContractDialog from '@publicSign/components/RejectContractDialog';

export default {
  name: 'PublicSignIntro',
  components: {
    RejectContractDialog,
  },
  data() {
    return {
      acceptsConditions: false,
      rejectRequest: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'publicContract/contract',
      contractAvatar: 'publicContract/avatar',
      contractLogo: 'publicContract/logo',
      contractSettings: 'publicContract/settings',
      hasFlattenFirstScreen: 'publicContract/hasFlattenFirstScreen',
      isLoading: 'publicContract/isLoading',
      layerColor: 'publicContract/layerColor',
      primaryColor: 'publicContract/primaryColor',
      primaryTextColor: 'publicContract/primaryTextColor',
    }),
    accessToken() {
      return this.$route.params.hash;
    },
    agreeWithTosText() {
      return this.$t('login.agree_with_tos_auto.app_links', {
        terms: this.$t('links.terms_and_conditions.unregistered'),
        privacy: this.$t('links.privacy_policy'),
      });
    },
    canRejectContract() {
      return this.contract?.current_user?.can_reject_some_contract;
    },
    contractDocuments() {
      if (this.contract?.attachments?.length) {
        return this.contract.attachments;
      }

      return [this.contract];
    },
    contractTitle() {
      if (!this.contract.attachments || 0 === this.contract.attachments.length) {
        return this.contract.contract_title;
      }

      let contractTitle = '';
      _.each(this.contract?.attachments, (attachment, index) => {
        contractTitle += attachment.contract_title;
        if (this.contract?.attachments?.length !== index + 1) {
          contractTitle += ', ';
        }
      });
      return contractTitle;
    },
    hideTermsAgreement() {
      return this.contractSettings?.hide_terms || false;
    },
  },
  beforeCreate() {
    // localStorage.removeItem('sign_app_locale');
  },
  methods: {
    onView() {
      return this.$router.push({
        name: `${this.contract.skip_header_changing ? 'contractView' : 'contractData'}`,
        params: {
          token: `${this.accessToken}`,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contract__data-text {
  background-color: #f2f6fc;
  border: 1px solid #e0e3e8;
  border-radius: 6px;
  margin: 20px auto;
  padding-top: 1rem;
  width: 240px;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.contract__data-download {
  background-color: #f2f6fc;
  width: 100%;
  max-width: 240px;
  border: 1px solid #e0e3e8;
  border-radius: 8px;
  margin: 0 auto;

  .v-btn__content {
    width: 100%;
    white-space: normal;
  }

  &-button {
    color: #5e239e;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.text-with-link::v-deep a {
  color: inherit !important;
  font-weight: inherit;

  &:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
